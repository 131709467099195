<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
    <div class="container-fluid flex-column">
      <div
        class="
          d-inline-flex
          flex-row
          w-100
          justify-content-between
          align-items-center
        "
      >
        <div>
          <small class="fw-light"
            ><i class="fas fa-user"></i> {{ email }}</small
          ><br />
          <button type="button" class="btn btn-outline-secondary btn-sm" @click="signOut">
            Sign Out
          </button>
        </div>
        <a class="navbar-brand mx-auto d-none d-lg-block" href="/"
          ><img src="../assets/logo.png" class="img-fluid nav-logo"
        /></a>
        <a
          class="navbar-brand float-right d-sm-block d-md-block d-lg-none"
          href="/"
          ><img src="../assets/logo.png" class="img-fluid nav-logo"
        /></a>
        <div class="d-none d-lg-block">
          <div
            class="d-flex flex-column align-items-center"
            style="color: #939597; width: 150px; margin-right: 10px"
          >
            <div>
              <span style="font-size: 16px"
                ><strong>STAY CONNECTED</strong></span
              >
            </div>
            <div>
              <a href="https://t.me/jhmetaphysics" target="_blank"
                ><i class="fab fa-telegram fa-fw social-icons"></i
              ></a>
              <a href="https://wa.me/6592714988" target="_blank"
                ><i class="fab fa-whatsapp-square fa-fw social-icons"></i
              ></a>
              <a href="https://facebook.com/jhmasterpan" target="_blank"
                ><i class="fab fa-facebook fa-fw social-icons"></i
              ></a>
              <a href="tel:+6592714988"
                ><i class="fas fa-phone-square fa-fw social-icons"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Auth } from "aws-amplify";
export default {
  name: "TopNav",
  props: ["email"],
  methods: {
    toggleOffcanvas() {
      //document.querySelector('.offcanvas-collapse').classList.toggle('open')
      this.$refs.offcanvas.classList.toggle("open");
    },
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
  padding-top: 16vh;
  background-color: #fff;
}
main {
  margin-top: 10px;
}
nav {
  padding-bottom: 0 !important;
}
.nav-logo {
  transform: translateX(0.5vw);
  height: 10vh;
}
.nav-logo-cta {
  padding-left: var(--bs-gutter-x, 0.75rem);
  height: 10vh;
}
.social-icons {
  color: #939597;
}
.social-icons:hover {
  filter: invert(100%);
}
.nav-custom {
  background-color: #f5f5f5;
  color: #939597;
}
.nav-custom.active {
  background-color: #939597;
  color: #f5f5f5;
}
.nav-custom:hover {
  background-color: #939597;
  color: #f5f5f5;
}
.nav-custom-halfborder {
  position: relative;
}
.nav-custom-halfborder:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 25%;
  height: 50%;
  border-left: 1px #75787b solid;
}
.nav-custom-mobile {
  background-color: #f5f5f5;
  color: #939597;
}
.nav-custom-mobile:hover {
  color: #f5f5f5;
  background-color: #939597;
}
.nav-custom-mobile.active {
  color: #f5f5f5;
  background-color: #939597;
}
.yellow-line {
  background-color: #f5df4d;
  width: 150px;
  height: 2px;
}
.grey-line {
  background-color: #75787b;
  height: 0.5px;
}
.subheader {
  color: #75787b;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .social-icons {
    font-size: 25px;
  }
}
@media (max-width: 991.98px) {
  body {
    padding-top: calc(10vh + 16px);
  }
  .nav-logo {
    height: 10vh;
  }
  .nav-logo-cta {
    padding-left: 0;
    height: 10vh;
  }
  .offcanvas-collapse {
    position: fixed;
    top: calc(10vh + 16px); /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f5f5f5;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.modal-title {
  color: #75787b;
}
h5.modal-title {
  color: #003a70;
}
.modal-title > small {
  color: #75787b !important;
}
.modal-body {
  color: #939597;
  font-size: 0.9rem;
}
.page-header {
  margin-top: -18px;
}
</style>
