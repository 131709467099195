<template>
  <div class="container">
    <div class="d-flex align-items-center my-5" v-if="loading">
      <div class="spinner-border text-danger" role="status"></div>
      <div class="ms-3">
        <strong>Loading...</strong>
      </div>
    </div>
    <nav class="my-5" v-if="!loading">
      <ul class="pagination pagination-lg flex-wrap justify-content-center">
        <li
          v-for="key in keys"
          :key="key.SK"
          class="page-item mb-3"
          :class="{ active: this.selectedKey == key.SK }"
        >
          <a class="page-link" href="#" @click="loadCustomers(key.SK)">{{
            key.SK
          }}</a>
        </li>
      </ul>
    </nav>
    <table
      class="table table-hover align-middle"
      v-if="selectedCustomers.length > 0"
      style="margin-bottom:405px;"
    >
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Gender</th>
          <th scope="col">Birthday</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in selectedCustomers" :key="customer.SK">
          <td>{{ customer.Name }}</td>
          <td>{{ customer.Gender }}</td>
          <td>{{ customer.BirthdayDisp }}</td>
          <td>
            <button
              type="button"
              class="btn btn-outline-dark"
              @click="viewCustomer(customer)"
            >
              <i class="far fa-eye"></i> View</button
            >&nbsp;&nbsp;<button
              type="button"
              class="btn btn-outline-dark"
              @click="deleteCustomer(customer)"
              v-bind:disabled="deletingSK == customer.SK"
            >
              <i
                class="fas fa-minus-circle"
                v-if="deletingSK != customer.SK"
              ></i
              ><i
                class="fas fa-spinner fa-spin"
                v-if="deletingSK == customer.SK"
              ></i>
              Delete</button
            >&nbsp;&nbsp;<button
              type="button"
              class="btn btn-outline-dark"
              @click="addToWorkbench(customer)"
              v-if="!workbenchSK.includes(customer.SK)"
            >
              <i class="fas fa-plus"></i> Workbench</button
            ><button
              type="button"
              class="btn btn-outline-dark"
              @click="deleteFromWorkbench(customer)"
              v-if="workbenchSK.includes(customer.SK)"
            >
              <i class="fas fa-minus"></i> Workbench
            </button>&nbsp;&nbsp;
            <div class="btn-group" role="group">
              <button
                type="button"
                class="btn btn-outline-dark dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-calendar-alt"></i> Calendar
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" @click="viewCalendar(customer, 1, 'Jan-2025')">Jan-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 2, 'Feb-2025')">Feb-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 3, 'Mar-2025')">Mar-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 4, 'Apr-2025')">Apr-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 5, 'May-2025')">May-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 6, 'Jun-2025')">Jun-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 7, 'Jul-2025')">Jul-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 8, 'Aug-2025')">Aug-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 9, 'Sep-2025')">Sep-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 10, 'Oct-2025')">Oct-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 11, 'Nov-2025')">Nov-2025</a></li>
                <li><a class="dropdown-item" @click="viewCalendar(customer, 12, 'Dec-2025')">Dec-2025</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { DateTime } from "luxon";
import { API } from "aws-amplify";
import * as _ from "lodash";

export default {
  name: "Home",
  components: {},
  data: () => ({
    loading: false,
    selectedKey: null,
    keys: [],
    selectedCustomers: [],
    deletingSK: null,
  }),
  computed: {
    workbench() {
      return this.$store.state.workbench;
    },
    workbenchSK() {
      return this.$store.state.workbench.map((cust) => cust.SK);
    },
  },
  mounted() {
    this.getKeys();

    // dev test
    /*
    const apiName = "admin";
    const path = "/getAllRegistration";
    API.get(apiName, path).then(r => console.log(r))*/
  },
  watch: {},
  methods: {
    async getKeys() {
      this.loading = true;
      try {
        const apiName = "admin";
        const path = "/keys";
        this.keys = await API.get(apiName, path);
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
      this.loading = false;
    },
    async loadCustomers(key) {
      this.selectedKey = key;
      this.selectedCustomers = [];
      this.loading = true;
      try {
        const apiName = "admin";
        const path = "/customers";
        let customers = await API.get(apiName, path, {
          queryStringParameters: { key },
        });
        customers = _.sortBy(customers, (value) => value.Name.toUpperCase());
        customers = _.filter(customers, (value) => !value._Deleted);
        customers = _.map(customers, (customer) => {
          customer.Gender = customer.Gender == "m" ? "Male" : "Female";
          customer.BirthdayDisp =
            customer.Birthday.split("T")[1] == "99:99"
              ? DateTime.fromISO(
                  customer.Birthday.split("T")[0]
                ).toLocaleString(DateTime.DATE_MED) + " (No birth time)"
              : DateTime.fromISO(customer.Birthday).toLocaleString(
                  DateTime.DATETIME_MED
                );
          return customer;
        });
        this.selectedCustomers = customers;
      } catch (e) {
        console.log(e);
        alert(
          "An error has occurred. Please refresh the page and try again later."
        );
      }
      this.loading = false;
    },
    addToWorkbench(customer) {
      this.$store.commit("addToWorkbench", customer);
    },
    deleteFromWorkbench(customer) {
      this.$store.commit("deleteFromWorkbench", customer);
    },
    async deleteCustomer(customer) {
      if (confirm("This action cannot be undone. Are you sure?")) {
        this.deletingSK = customer.SK;
        try {
          const apiName = "admin";
          const path = "/deletecustomer";
          await API.get(apiName, path, {
            queryStringParameters: { PK: customer.PK, SK: customer.SK },
          });
          _.remove(this.selectedCustomers, customer);
          this.deletingSK = null;
        } catch (e) {
          console.log(e);
          alert(
            "An error has occurred. Please refresh the page and try again later."
          );
          this.deletingSK = null;
        }
      }
    },
    viewCustomer(customer) {
      this.$router.push({
        name: "Viewer",
        params: {
          name: customer.Name,
          gender: customer.Gender == "Male" ? "m" : "f",
          birthday: customer.Birthday,
          birthdayDisp: customer.BirthdayDisp,
          showButtons: true,
        },
      });
    },
    viewCalendar(customer, month, monthDisp) {
      this.$router.push({
        name: "Calendar",
        params: {
          name: customer.Name,
          gender: customer.Gender == "Male" ? "m" : "f",
          birthday: customer.Birthday,
          birthdayDisp: customer.BirthdayDisp,
          showButtons: true,
          month, monthDisp
        },
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 991.98px) {
  .custom-mobile-text {
    font-size: 0.9rem;
  }
  .page-header {
    margin-top: -8px;
  }
}

.services-container {
  color: #939597;
}
.services-container h5.card-title {
  color: #75787b;
}
.services-container h5.card-title-blue {
  color: #003a70;
}
.services-container h5.date {
  color: #939597;
  font-size: 0.85rem;
}
.services-container .card-text {
  font-size: 0.8rem;
}
.services-container h5 {
  font-size: 1.1rem;
}
</style>
